<template>
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll; background-color: #F7F5F9;">
        <ColourModal @selectColour="selectColour" />
        <EditTaskModal @updatetable="updateTable" @cancelEdit="cancelEdit" />
        <EditDrawer />
        <EditAppointmentModal @cancelEdit="cancelEditAppointment" @updated="updatedAppointment" />
        <TaskandAppointment @changeStartTime="changeStartTime" @changeEndTime="changeEndTime" :endTime="endTime" :startTime="startTime" @closeAddEvent="closeAddEvent" :visible="showEventCreationDialog" />
        <div class="dF jSB">
            <h4 class="mb-3 mt-3">{{sidebarSelection}}</h4>
        </div>

        <div v-if="true" class="calendar-view">
            <div class="p-4 small--calendar" style="background-color:#FFF; border:1px solid #e8e8e8; overflow-y:scroll">
                <div class="display-height">
                    <vue-cal
                    @change="onChange"
                        class="vuecal--date-picker smallCalendaEdit"
                        small
                        hide-view-selector
                        :time="false"
                        active-view="month"
                        :events="events"
                        :disable-views="['week','day']"
                        events-dot-on-year-view
                        @cell-click="logEvent">
                    </vue-cal>
                    <div class="mt-5">
                        <div class="dF aC" style="border-bottom:2px solid #D5E1EB; margin-bottom:.5rem; justify-content:space-between">
                            <h6>My Calendars</h6>
                            <div @click="openAddNew" class="dF aC" style="margin-bottom:.5rem; color:var(--orange); cursor:pointer">
                                <i class="fe fe-plus mr-2" />
                                <div>Add</div>
                            </div>
                        </div>
                        <div class="dF aC" style="justify-content:space-between">
                            <div class="dF aC">
                                <div @click="changeColour('task')" class="mr-2" style="height:15px; width:15px; cursor:pointer;" :style="{'background-color':taskColour}"></div>
                                <div style="color:#8C97B2">Tasks</div>
                            </div>
                            <a-checkbox :checked="showAllTasks" @change="(e) => checkChange(e, 'task')" />
                        </div>
                        <div class="dF aC" style="justify-content:space-between">
                            <div class="dF aC">
                                <div @click="changeColour('appointment')" class="mr-2" style="height:15px; width:15px; cursor:pointer;" :style="{'background-color':appointmentColour}"></div>
                                <div style="color:#8C97B2">Appointments</div>
                            </div>
                            <a-checkbox :checked="showAllAppointments" @change="(e) => checkChange(e, 'appointment')" />
                        </div>
                        <div class="dF aC" style="justify-content:space-between">
                            <div class="dF aC">
                                <div @click="changeColour('opportunity')" class="mr-2" style="height:15px; width:15px; cursor:pointer;" :style="{'background-color':opportunityColour}"></div>
                                <div style="color:#8C97B2">Opportunities</div>
                            </div>
                            <a-checkbox :checked="showAllOpportunities" @change="(e) => checkChange(e, 'opportunity')" />
                        </div>
						<div class="dF aC" style="justify-content:space-between">
                            <div class="dF aC">
                                <div @click="changeColour('event')" class="mr-2" style="height:15px; width:15px; cursor:pointer;" :style="{'background-color':eventColour}"></div>
                                <div style="color:#8C97B2">Events</div>
                            </div>
                            <a-checkbox :checked="showAllEvents" @change="(e) => checkChange(e, 'event')" />
                        </div>
                    </div>
                </div>
            </div>
            <div style="flex:1" class="main--calendar">
                <a-card>
                    <div slot="title" class="dF aB">
                        <div style="font-size:20px" class="mr-5">Overview</div>
                        <div class="mr-5" style="color:#9EA0A5; font-size: 12px;">Updated at {{lastUpdated == 0 ? moment(Date.now()).format('LT') + ' on ' + moment(Date.now()).format('LL'):moment(lastUpdated).format('LT') + ' on ' + moment(lastUpdated).format('LL')}}</div>
                    </div>
                </a-card>
                <div class="main-height">
                <vue-cal
					:small="$mq !== 'lg' ? true: false"
                    :min-event-width="0"
                    resizeX
                    :overlapsPerTimeStep="false"
                    ref="vuecal"
                    class="calendarEdit"
                    :disable-views="['years', 'year']"
                    :show-all-day-events="'short'"
                    :time-from="6 * 60"
                    :time-to="20 * 60"
                    :events="events"
                    events-on-month-view
                    :editable-events="{ title: true, create: true }"
                    @change="calendarChange"
                    :on-event-create="onEventCreate"
                    @event-drag-create="openDialog"
					:twelveHour="timeFormat == 12 ? true : false"
                    :selected-date="selectedDate">


                    <!-- <template slot="today-button">adf</template> -->
                    <div slot="event" class="relative w-full h-full" slot-scope="obj">
                        <template v-if="obj.view == 'day'">
                            <a-popover trigger="click">
                                <div slot='content'>
                                    <div class="dF jE mb-3">
                                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
											<template slot="title">Edit Event</template>
											<svg @click="editEvent(obj)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 edit-button mr-3"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
										</a-tooltip>
										<a-tooltip slot="extra" overlayClassName="change-tooltip-color">
											<template slot="title">Delete Event</template>
											<i style="cursor:pointer; font-size: 18px; color:#FFF; color:var(--danger)" @click="deleteEvent(obj)" class="fe fe-trash-2" />
										</a-tooltip>
                                    </div>
                                    <div class="dF">
                                        <div class="mr-2" :style="obj.event.hasOwnProperty('priority') ? `background-color:${taskColour}`:obj.event.hasOwnProperty('class') && obj.event.class == 'appointment' ? `background-color:${appointmentColour}`: obj.event.class == 'event' ? `background-color:${eventColour}`: obj.event.hasOwnProperty('class') && obj.event.class=='opportunity' ? `background-color:${opportunityColour}`:''" style="width:20px; height:20px; border-radius:4px"></div>
                                        <div>
                                            <h5 style="margin:0">{{obj.event.title ? obj.event.title : obj.event.name ? obj.event.name:''}}</h5>
                                            <div v-if="!obj.event.hasOwnProperty('priority') && obj.event.hasOwnProperty('class') && obj.event.class == 'appointment'" class="dF aC">
                                                <div>{{convertDate(obj.event.date)}}</div>&nbsp;<div>|</div>&nbsp;
                                                <div>{{getRange(obj.event.startTime, obj.event.endTime)}}</div>
                                            </div>
											<div v-else-if="!obj.event.hasOwnProperty('priority') && obj.event.hasOwnProperty('class') && obj.event.class == 'event'" class="dF aC">
                                                <div>{{convertDate(obj.event.startTime)}}</div>&nbsp;<div>|</div>&nbsp;
                                                <div>{{getRange(obj.event.startTime, obj.event.endTime)}}</div>
                                            </div>
                                            <div v-else-if="!obj.event.hasOwnProperty('priority') && obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity'">
                                                <div>{{convertDate(obj.event.closeDate)}}</div>
                                                <div>{{`${getStage(obj.event.stage)} | ${obj.event.probability}%`}}</div>
                                            </div>
                                            <div v-else>{{obj.event.dueDate == 0 ? 'No Due Date':`${convertDate(obj.event.dueDate)}`}}</div>
                                            <div>{{obj.event.hasOwnProperty('priority') ? getType('task', obj.event.type): obj.event.hasOwnProperty('class') && obj.event.class == 'appointment' ? getType('appointment', obj.event.type) : getType('event', obj.event.type)}}</div>
                                        </div>
                                    </div>
                                    <div class="mt-3 dF" v-if="obj.event.location">
                                        <i style="font-size:20px" class="fe fe-map-pin mr-2" />
                                        <div>{{obj.event.location}}</div>
                                    </div>
									<div class="mt-3 dF" v-if="obj.event.link">
                                        <i style="font-size:20px" class="fe fe-link mr-2" />
                                        <div><a :href="obj.event.link" target="_blank">{{obj.event.link}}</a></div>
                                    </div>
                                    <div class="mt-3 dF" v-if="obj.event.hasOwnProperty('priority')">
                                        <i style="font-size:20px; width:20px" class="fa fa-exclamation mr-2" />
                                        <div>{{obj.event.priority.charAt(0).toUpperCase() + obj.event.priority.slice(1)}}</div>
                                    </div>
                                    <div class="mt-3 dF" v-if="obj.event.hasOwnProperty('class') && obj.event.class=='opportunity'">
                                        <i class="fe fe-dollar-sign mr-2" />
                                        <div>{{separator(obj.event.dealValue)}}</div>
                                    </div>
                                    <div class="mt-3 dF">
                                        <svg width="20" height="20" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users" class="svg-inline--fa fa-users fa-w-20 mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path></svg>
                                        <div>
                                            <div v-if="obj.event.assignTo && obj.event.assignTo">{{`${obj.event.assignTo.length+1} guest(s)`}}</div>
											<div v-if="obj.event.participantDetails && obj.event.participantDetails.length">{{`${obj.event.participantDetails.length} participant(s)`}}</div>
											<div v-else-if="obj.event.participantDetails && obj.event.participantDetails.length == 0">0 participants</div>
                                            <div v-if="obj.event.contact">{{obj.event.contact.firstName && obj.event.contact.lastName && obj.event.contact.firstName.trim() && obj.event.contact.lastName.trim() ? `${obj.event.contact.firstName} ${obj.event.contact.lastName}` : obj.event.contact.email}}</div>
                                            <div v-else>{{obj.event.opportunity ? obj.event.opportunity.name : ''}}</div>
                                            <template v-if="obj.event.assignTo && obj.event.assignTo.length">
                                                <div v-for="user in obj.event.assignTo" :key="user.id" :value="user.id">{{`${user.firstName} ${user.lastName}`}}</div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="mt-3 dF" v-if="obj.event.reminderType && obj.event.reminderType != 'none'">
                                        <i class="fe fe-bell mr-2" style="font-size:20px" />
                                        <div>{{obj.event.reminderType != 'onetime' ? `${getReminder(obj.event.reminderType)} Before` :`On ${getDateTime(obj.event.reminderOn)}`}}</div>
                                    </div>
                                </div>
                                <div class="mb-1" style="color:#FFF; width:100%; height:100%; padding:15px; border-radius:4px; cursor:pointer" :style="obj.event.hasOwnProperty('priority') ? `background-color:${taskColour}`:obj.event.hasOwnProperty('class') && obj.event.class == 'appointment' ? `background-color:${appointmentColour}` : obj.event.class == 'event' ? `background-color:${eventColour}`: obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity' ? `background-color:${opportunityColour}`:''">
                                    <div class="dF aC" style="justify-content:space-between">
                                        <div>{{obj.event.title ? obj.event.title:obj.event.name? obj.event.name : ''}}</div>
                                        <!-- <div class="dF aC">
                                            <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                <template slot="title">Edit Event</template>
                                                <svg @click="editEvent(obj)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 edit-button mr-3"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                            </a-tooltip>
                                            <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                <template slot="title">Delete Event</template>
                                                <i style="cursor:pointer; font-size: 18px; color:#FFF; color:var(--danger)" @click="deleteEvent(obj)" class="fe fe-trash-2" />
                                            </a-tooltip>
                                        </div> -->
                                    </div>
                                </div>
                            </a-popover>
                        </template>
                        <template v-if="obj.view == 'week'">
                            <a-popover trigger="focus">
                                <div tabindex="0" slot='content'>
                                    <div class="dF jE mb-3">
                                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                            <template slot="title">Edit Event</template>
                                            <svg @click="editEvent(obj)" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 edit-button mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                        </a-tooltip>
                                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                            <template slot="title">Delete Event</template>
                                            <i style="cursor:pointer; font-size: 15px; color:#FFF; color:var(--danger)" @click="deleteEvent(obj)" class="fe fe-trash-2" />
                                        </a-tooltip>

                                    </div>
                                    <div class="dF">
                                        <div class="mr-2" :style="obj.event.hasOwnProperty('priority') ? `background-color:${taskColour}`:obj.event.hasOwnProperty('class') && obj.event.class == 'appointment' ? `background-color:${appointmentColour}` : obj.event.class == 'event' ? `background-color:${eventColour}`: obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity' ? `background-color:${opportunityColour}` : ''" style="width:20px; height:20px; border-radius:4px"></div>
                                        <div>
                                            <h5 style="margin:0">{{obj.event.hasOwnProperty('priority') || (obj.event.hasOwnProperty('class') && obj.event.class == 'appointment' || obj.event.class == 'event') ? obj.event.title : obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity' ? obj.event.name : ''}}</h5>
                                            <div v-if="!obj.event.hasOwnProperty('priority') && obj.event.hasOwnProperty('class') && obj.event.class == 'appointment'" class="dF aC">
                                                <div>{{convertDate(obj.event.date)}}</div>&nbsp;<div>|</div>&nbsp;
                                                <div>{{getRange(obj.event.startTime, obj.event.endTime)}}</div>
                                            </div>
											<div v-else-if="!obj.event.hasOwnProperty('priority') && obj.event.hasOwnProperty('class') && obj.event.class == 'event'" class="dF aC">
                                                <div>{{convertDate(obj.event.startTime)}}</div>&nbsp;<div>|</div>&nbsp;
                                                <div>{{getRange(obj.event.startTime, obj.event.endTime)}}</div>
                                            </div>
                                            <div v-else-if="!obj.event.hasOwnProperty('priority') && obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity'">
                                                <div>{{`${convertDate(obj.event.closeDate)}`}}</div>
                                                <div>{{`${getStage(obj.event.stage)} | ${obj.event.probability}%`}}</div>
                                            </div>
                                            <div v-else>{{obj.event.dueDate == 0 ? 'No Due Date':`${convertDate(obj.event.dueDate)}`}}</div>
                                            <div>{{obj.event.hasOwnProperty('priority') ? getType('task', obj.event.type): obj.event.hasOwnProperty('class') && obj.event.class == 'appointment' ? getType('appointment', obj.event.type) : getType('event', obj.event.type)}}</div>
                                        </div>
                                    </div>
                                    <div class="mt-3 dF" v-if="obj.event.location">
                                        <i style="font-size:20px" class="fe fe-map-pin mr-2" />
                                        <div>{{obj.event.location}}</div>
                                    </div>
									<div class="mt-3 dF" v-if="obj.event.link">
                                        <i style="font-size:20px" class="fe fe-link mr-2" />
                                        <div><a :href="obj.event.link" target="_blank">{{obj.event.link}}</a></div>
                                    </div>
                                    <div class="mt-3 dF" v-if="obj.event.hasOwnProperty('priority')">
                                        <i style="font-size:20px; width:20px" class="fa fa-exclamation mr-2" />
                                        <div>{{obj.event.priority.charAt(0).toUpperCase() + obj.event.priority.slice(1)}}</div>
                                    </div>
                                    <div class="mt-3 dF" v-if="obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity'">
                                        <i class="fe fe-dollar-sign mr-2" style="font-size:20px" />
                                        <div>{{separator(obj.event.dealValue)}}</div>
                                    </div>
                                    <div class="mt-3 dF">
                                        <svg width="20" height="20" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users" class="svg-inline--fa fa-users fa-w-20 mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path></svg>
                                        <div>
                                            <div v-if="obj.event.assignTo && obj.event.assignTo.length">{{`${obj.event.assignTo.length+1} guest(s)`}}</div>
											<div v-if="obj.event.participantDetails && obj.event.participantDetails.length">{{`${obj.event.participantDetails.length} participant(s)`}}</div>
											<div v-else-if="obj.event.participantDetails && obj.event.participantDetails.length == 0">0 participants</div>
                                            <div v-if="obj.event.contact">{{obj.event.contact.firstName && obj.event.contact.lastName && obj.event.contact.firstName.trim() && obj.event.contact.lastName.trim() ? `${obj.event.contact.firstName} ${obj.event.contact.lastName}` : obj.event.contact.email}}</div>
                                            <div v-else>{{obj.event.opportunity ? obj.event.opportunity.name : ''}}</div>
                                            <template v-if="obj.event.assignTo && obj.event.assignTo.length">
                                                <div v-for="user in obj.event.assignTo" :key="user.id" :value="user.id">{{`${user.firstName} ${user.lastName}`}}</div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="mt-3 dF" v-if="obj.event.reminderType && obj.event.reminderType != 'none'">
                                        <i class="fe fe-bell mr-2" style="font-size:20px" />
                                        <div>{{obj.event.reminderType != 'onetime' ? `${getReminder(obj.event.reminderType)} Before` :`On ${getDateTime(obj.event.reminderOn)}`}}</div>
                                    </div>
                                </div>
                                <div tabindex="0" style="text-align:left; padding: 15px; height:100%; cursor:pointer" :style="obj.event.hasOwnProperty('priority') ? `border-top: 3px solid ${taskColour}`: obj.event.hasOwnProperty('class') && obj.event.class == 'appointment' ? `border-top: 3px solid ${appointmentColour}` : obj.event.class == 'event' ? `border-top:3px solid ${eventColour}`: obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity' ? `border-top:3px solid ${opportunityColour}` : ''">
                                    <div>
                                        <div class="dF aC" style="font-weight: bold;">
                                            <div style="flex:1; overflow:hidden">{{obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity' ? obj.event.name:obj.event.title}}</div>
                                            <div class="ml-2" v-if="obj.event.priority" :style="obj.event.priority == 'high' ? 'color:#E64C66':obj.event.priority == 'medium' ? 'color:var(--orange)' : 'color:var(--teal)'"><i class="fa fa-exclamation" /></div>
                                        </div>

                                        <div class="mt-3" v-if="!obj.event.allDay">
                                            {{obj.event.description? obj.event.description : obj.event.notes? obj.event.notes : ''}}
                                        </div>
                                    </div>
                                </div>
                            </a-popover>
                        </template>
                        <template v-if="obj.view == 'month'">
                            <a-popover trigger="focus">
                                <div slot='content'>
                                    <div class="dF jE mb-3">
                                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                                <template slot="title">Edit Event</template>
                                            <svg @click="editEvent(obj)" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 edit-button mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                        </a-tooltip>
                                        <a-tooltip slot="extra" overlayClassName="change-tooltip-color">
                                            <template slot="title">Delete Event</template>
                                            <i style="cursor:pointer; font-size: 15px; color:#FFF; color:var(--danger)" @click="deleteEvent(obj)" class="fe fe-trash-2" />
                                        </a-tooltip>

                                    </div>
                                    <div class="dF">
                                        <div class="mr-2" :style="obj.event.hasOwnProperty('priority') ? `background-color:${taskColour}`:obj.event.hasOwnProperty('class') && obj.event.class == 'appointment' ? `background-color:${appointmentColour}`: obj.event.class == 'event' ? `background-color:${eventColour}`: obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity' ? `background-color:${opportunityColour}`:''" style="width:20px; height:20px; border-radius:4px"></div>
                                        <div>
                                            <h5 style="margin:0">{{obj.event.title ? obj.event.title: obj.event.name ? obj.event.name:''}}</h5>
                                            <div v-if="!obj.event.hasOwnProperty('priority') && obj.event.hasOwnProperty('class') && obj.event.class == 'appointment'" class="dF aC">
                                                <div>{{convertDate(obj.event.date)}}</div>&nbsp;<div>|</div>&nbsp;
                                                <div>{{getRange(obj.event.startTime, obj.event.endTime)}}</div>
                                            </div>
											<div v-else-if="!obj.event.hasOwnProperty('priority') && obj.event.hasOwnProperty('class') && obj.event.class == 'event'" class="dF aC">
                                                <div>{{convertDate(obj.event.startTime)}}</div>&nbsp;<div>|</div>&nbsp;
                                                <div>{{getRange(obj.event.startTime, obj.event.endTime)}}</div>
                                            </div>
                                            <div v-else-if="!obj.event.hasOwnProperty('priority') && obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity'">
                                                <div>{{convertDate(obj.event.closeDate)}}</div>
                                            </div>
                                            <div v-else>{{obj.event.dueDate == 0 ? 'No Due Date':`${convertDate(obj.event.dueDate)}`}}</div>
                                            <div v-if="obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity'">{{`${getStage(obj.event.stage)} | ${obj.event.probability}%`}}</div>
                                            <div>{{obj.event.hasOwnProperty('priority') ? getType('task', obj.event.type): obj.event.hasOwnProperty('class') && obj.event.class == 'appointment' ? getType('appointment', obj.event.type) : getType('event', obj.event.type)}}</div>
                                        </div>
                                    </div>
                                    <div class="mt-3 dF" v-if="obj.event.location">
                                        <i style="font-size:20px" class="fe fe-map-pin mr-2" />
                                        <div>{{obj.event.location}}</div>
                                    </div>
									<div class="mt-3 dF" v-if="obj.event.link">
                                        <i style="font-size:20px" class="fe fe-link mr-2" />
                                        <div><a :href="obj.event.link" target="_blank">{{obj.event.link}}</a></div>
                                    </div>
                                    <div class="mt-3 dF" v-if="obj.event.hasOwnProperty('priority')">
                                        <i style="font-size:20px; width:20px" class="fa fa-exclamation mr-2" />
                                        <div>{{obj.event.priority.charAt(0).toUpperCase() + obj.event.priority.slice(1)}}</div>
                                    </div>
                                    <div class="mt-3 dF" v-if="obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity'">
                                        <i style="font-size:20px;" class="fe fe-dollar-sign mr-2" />
                                        <div>{{separator(obj.event.dealValue)}}</div>
                                    </div>
                                    <div class="mt-3 dF">
                                        <svg width="20" height="20" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users" class="svg-inline--fa fa-users fa-w-20 mr-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path></svg>
                                        <div>
                                            <div v-if="obj.event.assignTo && obj.event.assignTo.length">{{`${obj.event.assignTo.length+1} guest(s)`}}</div>
											<div v-if="obj.event.participantDetails && obj.event.participantDetails.length">{{`${obj.event.participantDetails.length} participant(s)`}}</div>
											<div v-else-if="obj.event.participantDetails && obj.event.participantDetails.length == 0">0 participants</div>
                                            <div v-if="obj.event.contact">{{obj.event.contact.firstName && obj.event.contact.lastName && obj.event.contact.firstName.trim() && obj.event.contact.lastName.trim() ? `${obj.event.contact.firstName} ${obj.event.contact.lastName}` : obj.event.contact.email}}</div>
                                            <div v-else>{{obj.event.opportunity ? obj.event.opportunity.name : ''}}</div>
                                            <div v-for="user in obj.event.assignTo" :key="user.id" :value="user.id">{{`${user.firstName} ${user.lastName}`}}</div>
                                        </div>
                                    </div>
                                    <div class="mt-3 dF" v-if="obj.event.reminderType && obj.event.reminderType != 'none'">
                                        <i class="fe fe-bell mr-2" style="font-size:20px" />
                                        <div>{{obj.event.reminderType != 'onetime' ? `${getReminder(obj.event.reminderType)} Before` :`On ${getDateTime(obj.event.reminderOn)}`}}</div>
                                    </div>
                                </div>
                                <div tabindex="0" class="mb-1" :style="obj.event.hasOwnProperty('priority') ? `background-color:${taskColour}`: obj.event.hasOwnProperty('class') && obj.event.class == 'appointment' ? `background-color:${appointmentColour}` : obj.event.class == 'event' ? `background-color:${eventColour}`: obj.event.hasOwnProperty('class') && obj.event.class == 'opportunity' ? `background-color:${opportunityColour}` : ''" style="color:#FFF; border-radius:4px; cursor:pointer">
                                    {{obj.event.title ? obj.event.title : obj.event.name ? obj.event.name:''}}
                                </div>
                            </a-popover>
                        </template>
                    </div>
                </vue-cal>
                </div>
            </div>
        </div>

        <template v-else>
            <EmptyApp :app="$store.state.theApp" />
        </template>

    </div>
</template>

<script>
    import BHLoading from 'bh-mod/components/common/Loading'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    import VueCal from 'vue-cal'
    import EditTaskModal from '@/components/contacts/EditTaskModal'
    import TaskandAppointment from '@/components/common/TaskandAppointment'
    import EditAppointmentModal from '@/components/contacts/EditAppointmentModal'
    import ColourModal from '@/components/common/ColourModal'
    import moment from 'moment'
	import 'moment-timezone';
    import 'vue-cal/dist/vuecal.css'

    import EditDrawer from '@/components/contacts/EditDrawer'

    export default {
        components:{
            BHLoading,EmptyApp, VueCal,EditTaskModal,EditAppointmentModal,ColourModal,TaskandAppointment,EditDrawer
        },
        data() {
            return {
                // visible:false,
                expandTask:false,
                expandAppointment:false,
                test:0,
                activeView:'week',
                lastUpdated:0,
                showEventCreationDialog:false,
                selectedEvent: null,
                endTime:'',
                startTime:'',
                showAllTasks:true,
                showAllAppointments:true,
                showAllOpportunities:true,
				showAllEvents:true,
                selectedDate:''
                // selectedDate:Date.now()
            }
        },
        watch:{

        },
        computed:{
			timezone(){
				return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeZone && typeof this.$store.state.contacts.allSettings.user.options.regional.timeZone == 'string' ? this.$store.state.contacts.allSettings.user.options.regional.timeZone : 'America/New_York'
			},
			timeFormat(){
				return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeFormat ? this.$store.state.contacts.allSettings.user.options.regional.timeFormat : 12
			},
			dateFormat(){
				return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
			},
            dealStages() {
                return this.$store.state.contacts.allSettings.app.options.stages.list
            },
            taskTypes() {
                if (this.$store.state.contacts?.allSettings?.app?.options?.taskType?.length) {
                    return this.$store.state.contacts.allSettings.app.options.taskType
                } else {
                    return [
                        {name:'To-do', id:'todo'},
                        {name:'Call', id:'call'},
                        {name:'Follow up', id:'followup'}
                    ]
                }
            },
            appointmentTypes() {
                return [
					{ name: 'Virtual Meeting', id: 'virtualmeeting' },
					{ name: 'Phone Meeting', id: 'phonemeeting' },
					{ name: 'Sales Center', id: 'salescentre' }
				]
            },
			eventTypes() {
                return [
					{ name: 'Virtual Meeting', id: 'virtual' },
					{ name: 'Physical Meeting', id: 'physical' }
				]
            },
            events(){
                console.log('EVENTS RAN')
                let test = this.test
                let list = []

                if (this.showAllTasks && this.tasks && this.tasks.length){
                    this.tasks.forEach(x => {
                        if (!x.completed){
                            let containsUser = false
                            x.assignTo.forEach( y => {
                                if (y.id == this.user.user.id){
                                    containsUser = true
                                }
                            })

                            if (containsUser){
                                let createdDate = this.formatDate(x.createdAt)
                                let dueDate = this.formatDate(x.dueDate)
                                let endTime = moment(x.dueDate).format('YYYY-MM-DD hh:mm')
                                if (parseInt(this.moment(x.updatedAt).format('X'))*1000 > this.lastUpdated){
                                    console.log('LAST UPDATED')
                                    this.lastUpdated = parseInt(this.moment(x.updatedAt).format('X'))*1000
                                }

                                x.start = dueDate
                                x.end = endTime
                                x.allDay = true
                                x.class = x.priority
                                list.push(x)
                            }
                        }
                    })
                    console.log('tasksss =>>', list)
                }

                if (this.showAllAppointments && this.appointments && this.appointments.length){
                    this.appointments.forEach(x => {
                        let containsUser = false
                        x.assignTo.forEach( y => {
                            if (y.id == this.user.user.id){
                                containsUser = true
                            }
                        })
                        if (containsUser){
                            x.start = this.formatAppointmentDate(x.date,x.startTime)
                            x.end = this.formatAppointmentDate(x.date,x.endTime)
                            x.class = 'appointment'
                            if (parseInt(this.moment(x.updatedAt).format('X'))*1000 > this.lastUpdated){
                                console.log('LAST UPDATED')
                                this.lastUpdated = parseInt(this.moment(x.updatedAt).format('X'))*1000
                            }
                            list.push(x)
                        }
                    })
                }

                if (this.showAllOpportunities && this.opportunities && this.opportunities.length) {
                    this.opportunities.forEach(x => {
                        let userIds = x.owners.map(x => x = x.id)
                        if (userIds.includes(this.user.user.id)) {
                            let closeDate = this.formatDate(x.closeDate)
                            if (parseInt(this.moment(x.updatedAt).format('X'))*1000 > this.lastUpdated){
                                console.log('LAST UPDATED')
                                this.lastUpdated = parseInt(this.moment(x.updatedAt).format('X'))*1000
                            }
                            x.end = closeDate
                            x.start = closeDate
                            x.allDay = true
                            x.class = 'opportunity'
                            list.push(x)
                        }
                    })
                }

				if (this.showAllEvents && this.eventSlots && this.eventSlots.length){
                    this.eventSlots.forEach(x => {
                        let containsUser = false
						if (x.assignTo.id == this.user.user.id){
							containsUser = true
						}
                        if (containsUser){
                            x.start = this.formatAppointmentDate(x.startTime,x.startTime)
                            x.end = this.formatAppointmentDate(x.startTime,x.endTime)
                            x.class = 'event'
                            if (parseInt(this.moment(x.updatedAt).format('X'))*1000 > this.lastUpdated){
                                console.log('LAST UPDATED')
                                this.lastUpdated = parseInt(this.moment(x.updatedAt).format('X'))*1000
                            }
                            list.push(x)
                        }
                    })
                }

                console.log('aathislisttt', list)
                console.log('LAST UPDATEDDDD', this.moment(this.lastUpdated).format())
                return list
            },
            sidebarSelection(){
                return this.$store.state.sidebarSelection
            },
            instance(){
                return this.$store.state.instance
            },
            tasks(){
                return this.$store.state.contacts.tasks
            },
            appointments(){
                return this.$store.state.contacts.appointments
            },
            opportunities() {
                return this.$store.state.contacts.opportunities
            },
			eventSlots(){
				let eventGroups = this.$store.state.contacts.events
				let slots = []
				eventGroups.forEach(x => {
					let activeSlots = x.eventSlots.filter(y => y.status != 'archived')
					activeSlots.forEach(y => {
						y.assignTo = x.assignTo
						y.title = x.title
						y.type = x.meetingSettings.preferredEventType
					})
					slots = slots.concat(activeSlots)
				})
				return slots
			},
            user(){
                return this.$store.state.user
            },
            taskColour() {
                if (this.$store.state.contacts.allSettings && this.$store.state.contacts.allSettings.userApp && this.$store.state.contacts.allSettings.userApp.options && this.$store.state.contacts.allSettings.userApp.options.colour && this.$store.state.contacts.allSettings.userApp.options.colour.task && this.$store.state.contacts.allSettings.userApp.options.colour.task != '') {
                    return this.$store.state.contacts.allSettings.userApp.options.colour.task
                } else {
                    return '#2C94C8'
                }
            },
            appointmentColour() {
                if (this.$store.state.contacts.allSettings && this.$store.state.contacts.allSettings.userApp && this.$store.state.contacts.allSettings.userApp.options && this.$store.state.contacts.allSettings.userApp.options.colour && this.$store.state.contacts.allSettings.userApp.options.colour.appointment && this.$store.state.contacts.allSettings.userApp.options.colour.appointment != '') {
                    return this.$store.state.contacts.allSettings.userApp.options.colour.appointment
                } else {
                    return '#3A558A'
                }
            },
            opportunityColour() {
                if (this.$store.state.contacts.allSettings && this.$store.state.contacts.allSettings.userApp && this.$store.state.contacts.allSettings.userApp.options && this.$store.state.contacts.allSettings.userApp.options.colour && this.$store.state.contacts.allSettings.userApp.options.colour.opportunity && this.$store.state.contacts.allSettings.userApp.options.colour.opportunity != '') {
                    return this.$store.state.contacts.allSettings.userApp.options.colour.opportunity
                } else {
                    return '#FDA28F'
                }
            },
			eventColour() {
                if (this.$store.state.contacts.allSettings && this.$store.state.contacts.allSettings.userApp && this.$store.state.contacts.allSettings.userApp.options && this.$store.state.contacts.allSettings.userApp.options.colour && this.$store.state.contacts.allSettings.userApp.options.colour.event && this.$store.state.contacts.allSettings.userApp.options.colour.event != '') {
                    return this.$store.state.contacts.allSettings.userApp.options.colour.event
                } else {
                    return '#28a745'
                }
            },
            userSettings() {
                if(this.$store.state.contacts.allSettings && this.$store.state.contacts.allSettings.userApp && this.$store.state.contacts.allSettings.userApp.options) {
                    return this.$store.state.contacts.allSettings.userApp.options
                } else return {}
            }
        },
        methods:{
			offsetDiffMs(type){
				let desiredTimezone = moment.tz(this.timezone);
				let desiredOffset = desiredTimezone.utcOffset();
				let currentOffset = new Date().getTimezoneOffset() * -1;
				let offsetDiff = 0
				let offsetDiffMs = 0
				if (currentOffset !== desiredOffset){
					if (type == 'set'){
						offsetDiff = currentOffset - desiredOffset;
					} else {
						offsetDiff = desiredOffset - currentOffset;
					}
					offsetDiffMs = offsetDiff * 60 * 1000;
				}
				return offsetDiffMs
			},
            separator(numb) {
                let str = numb.toString().split(".");
                str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return str.join(".");
            },
            getStage(id) {
                let index =  this.dealStages.findIndex(x => x.id == id)
                if (index != -1) {
                    return `${this.dealStages[index].name}`
                } else return ''
            },
            getDateTime(num) {
                if (num != 0 && num != undefined) {
                    let x = new Date(num)
                    let date = this.convertDate(num)
					let time = this.convertTime(num)
                    return `${date} | ${time}`
                } else return ''
            },
			convertTime(time){
				let x = new Date(time)
				x.setTime(x.getTime() + this.offsetDiffMs('get'));
				let hours = x.getHours()
				let minutes = x.getMinutes()
				minutes = minutes < 10 ? '0'+minutes : minutes

				let strTime = hours + ':' + minutes

				if (this.timeFormat == 12){
					let ampm = hours >= 12 ? 'PM' : 'AM'
					hours = hours % 12
					hours = hours ? hours : 12 // the hour '0' should be '12'
					strTime = hours + ':' + minutes + ' ' + ampm
				}

				return strTime
			},
            getReminder(id) {
                let reminders = {
                    'r15m':'15 Minutes',
                    'r30m':'30 Minutes',
                    'r1h':'1 Hour',
                    'r2h':'2 Hours',
                    'r6h':'6 Hours',
                    'r12h':'12 Hours',
                    'r1d':'1 Day',
                    'r2d':'2 Days',
                    'r1w':'1 Week',
                }
                if (reminders[id]) {
                    return reminders[id]
                } else return ''
            },
            getType(type,id) {
                if (type == 'appointment') {
                    let find = this.appointmentTypes.find(x => x.id == id)
                    if (find) return find.name
                } else if (type == 'event') {
					let find = this.eventTypes.find(x => x.id == id)
					if (find) return find.name
				} else if (type == 'task') {
                    let find = this.taskTypes.find(x => x.id == id)
                    if (find) return find.name
                } else return ''
            },
            getRange(start, end) {
                let startTime = this.convertTime(start)
				let endTime = this.convertTime(end)
				return `${startTime} - ${endTime}`
            },
            convertDate(num){
				let x = new Date(num)
				//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
				x.setTime(x.getTime() + this.offsetDiffMs('get'));
				let year = x.getFullYear()
				let month = x.getMonth()+1
				let day = x.getDate()
				if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
				else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
				else {
					return month+'/'+day+'/'+year
				}
			},
            openAddNew() {
                this.showEventCreationDialog = true
                this.endTime = moment(Date.now())
                this.startTime = moment(Date.now())
            },
            logEvent(e) {
                this.selectedDate = e
            },
            checkChange(e, type) {
                if (type == 'task') {
                    if (e.target.checked) {
                        this.showAllTasks = true
                    } else {
                        this.showAllTasks = false
                    }
                } else if (type == 'appointment') {
                    if (e.target.checked) {
                        this.showAllAppointments = true
                    } else {
                        this.showAllAppointments = false
                    }
                } else if (type == 'opportunity') {
                    if (e.target.checked) {
                        this.showAllOpportunities = true
                    } else {
                        this.showAllOpportunities = false
                    }
                } else if (type == 'event') {
                    if (e.target.checked) {
                        this.showAllEvents = true
                    } else {
                        this.showAllEvents = false
                    }
                }
            },
            changeEndTime(time) {
                this.endTime = time
            },
            changeStartTime(time) {
                this.startTime = time
            },
            closeAddEvent() {
                this.cancelEventCreation()
                // this.starTime = 0
                // this.endTime = 0
            },
            calendarChange(e) {
                console.log('eee',e)
            },
            onEventCreate (event, deleteEventFunction) {

                event.title = 'New Event'
                this.selectedEvent = event
                let month = ''
                let day = ''
                let year = ''
                let end = ''
                let hour = ''
                let min = ''
                if (Object.keys(event).length != 0) {
                    month = (event.end.getMonth()+1).toString()
                    day = event.end.getDate().toString()
                    year = event.end.getFullYear().toString()
                    hour = event.end.getTime()
                    min = event.end.getMinutes()
                }
                console.log('new eventt', this.selectedEvent, month, day, year, end, hour, min)
                this.deleteEventFunction = deleteEventFunction


                return event
            },
            openDialog() {
                console.log('eventt', this.selectedEvent)
                this.endTime = moment(this.selectedEvent.end)
                this.startTime = moment(this.selectedEvent.start)
                this.showEventCreationDialog = true
            },
            cancelEventCreation () {
                if (this.selectedEvent && Object.keys(this.selectedEvent).length != 0) {
                    this.deleteEventFunction()
                }
                this.closeCreationDialog()
            },
            closeCreationDialog () {
                this.showEventCreationDialog = false
                this.selectedEvent = {}
            },
            selectColour(object) {
                let settings = JSON.parse(JSON.stringify(this.userSettings))
                let sendObj = {}
                if (Object.keys(settings).length != 0) {
                    Object.keys(settings).forEach(key => {
                        sendObj[key] = settings[key]
                    })
                    if (!sendObj.colour) {
                        let colour = {}
                        colour[object.name] = object.colour
                        sendObj.colour = colour
                    } else {
                        sendObj.colour[object.name] = object.colour
                    }
                } else {
                    sendObj.colour[object.name] = object.colour
                }
                this.$api.post(`/settings/:instance/contacts/user`, {options:sendObj}).then((data) => {
                    console.log('dataaaa', data)
                    this.$store.commit('UPDATE_USER_ALLSETTINGS', data.data)
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            },
            changeColour(type) {
                if (type == 'task') {
                    let object = {}
                    object.name = type
                    object.colour = this.taskColour
                    this.$store.commit('OPEN_COLOUR', object)
                } else if (type == 'appointment') {
                    let object = {}
                    object.name = type
                    object.colour = this.appointmentColour
                    this.$store.commit('OPEN_COLOUR', object)
                } else if (type == 'opportunity') {
                    let object = {}
                    object.name = type
                    object.colour = this.opportunityColour
                    this.$store.commit('OPEN_COLOUR', object)
                }
            },
            onChange(e) {
                console.log('e',e)
            },
            moment,
            updatedAppointment(){
                console.log('UPDATE APPOINTMENT RAN')
                this.test = Date.now()
            },
            updateTable(){
                console.log('UPDATE TABLE RAN')
                this.test = Date.now()
            },
            formatDate(s){
                let numDate = new Date(s)
                let month = numDate.getMonth()+1
                let day = numDate.getDate()
                if (day.length == 1) day = '0'+day
                let year = numDate.getFullYear()
                let time = numDate.getTime()
                let formatTime = this.moment(time).format('HH:mm')
                let finalCreatedDate = year+'-'+month+'-'+day+' '+formatTime
                return finalCreatedDate
            },
            formatAppointmentDate(d,t){
                let numDate = new Date(d)
                let onlyTime = new Date(t)

                let month = numDate.getMonth()+1
                let day = numDate.getDate()
                let year = numDate.getFullYear()
                let time = onlyTime.getTime()
                time = this.moment(time).format('HH:mm')

                let initialCreatedDate = year+'-'+month+'-'+day+' '+time


				let finalCreatedDate = parseInt(this.moment(initialCreatedDate).format('X'))*1000 + this.offsetDiffMs('get')
				let newDate = new Date(finalCreatedDate)

				month = newDate.getMonth()+1
				day = newDate.getDate()
				year = newDate.getFullYear()
				time = newDate.getTime()
				time = this.moment(time).format('HH:mm')

				initialCreatedDate = year+'-'+month+'-'+day+' '+time

                return initialCreatedDate
            },
            printTime(time){
                let t = time.toString()
                return t.substring(16,21)
            },
            editEvent(item){
                console.log('EDIT EVENT CLICKED', item)
                if (item.event.class == 'low' || item.event.class == 'medium' || item.event.class == 'high') {
                    let editTask = this.tasks.find(x => x.id == item.event.id)
                    this.$store.commit('EDIT_TASK', editTask)
                } else if (item.event.hasOwnProperty('class') && item.event.class == 'appointment'){
                    console.log('appointment', item.event)
                    let editAppointment = this.appointments.find(x => x.id == item.event.id)
                    this.$store.commit('EDIT_APPOINTMENT', editAppointment)
                    // this.$store.commit('OPEN_EDIT_DRAWER', {type:'appointment', id:item.event.id})
                    if (editAppointment.hasOwnProperty('contact')){
                        console.log('RUNNING UPDATE QUERY CONTACT', editAppointment.contact.firstName)
                        setTimeout(() => {
                            this.$store.commit('UPDATE_QUERY_CONTACT', editAppointment.contact)
                        }, 50);
                    }

                } else if (item.event.hasOwnProperty('class') && item.event.class == 'event'){
                    console.log('event', item.event)
                    this.$router.push(`/events/${item.event.eventGroup}`)

                } else if (item.event.hasOwnProperty('class') && item.event.class == 'opportunity') {
                    this.$router.push(`/opportunities/sales/${item.event.id}`)
                }

            },
            deleteEvent(item) {
                console.log('DELETE EVENT CLICKED', item)
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete this')
                }
                if (item.event.class == 'low' || item.event.class == 'medium' || item.event.class == 'high'){
                    let self = this
                    this.$confirm({
                        title: "Delete Task",
                        content: h => <div>Do you want to delete this Task?</div>,
                        okText: 'Delete',
                        okType: 'danger',
                        cancelText: 'Cancel',
                        centered: true,
                        onOk() {
                            self.$api.delete(`/tasks/:instance/${item.event.id}`).then( ({data}) => {
                                console.log('just delete', data)
                                self.$store.commit('DELETE_TASK', data)
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									self.$message.error(self.$err(err))
								}
							})
                        },
                        onCancel() {
                            console.log('Cancel')
                        }
                    })
                } else if (item.event.hasOwnProperty('class') && item.event.class == 'appointment'){
                    let self = this
                    this.$confirm({
                        title: "Delete Appointment",
                        content: h => <div>Do you want to delete this Appointment?</div>,
                        okText: 'Delete',
                        okType: 'danger',
                        cancelText: 'Cancel',
                        centered: true,
                        onOk() {
                            self.$api.delete(`/appointments/:instance/${item.event.id}`).then( ({data}) => {
                                console.log('just delete', data)
                                self.$store.commit('DELETE_APPOINTMENT', data)
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									self.$message.error(self.$err(err))
								}
							})
                        },
                        onCancel() {
                            console.log('Cancel')
                        }
                    })
                } else if (item.event.hasOwnProperty('class') && item.event.class == 'event'){
                    if (this.$p < 15 && (!obj.createdBy || obj.createdBy.id !== this.$store.state.user.user.id)){
						return this.$message.error('You do not have permission to delete Events')
					}
					let self = this
					this.$confirm({
						title: 'Delete Event',
						content: h => <div>Do you want to delete this event?</div>,
						okText: 'DELETE',
						okType: 'danger',
						cancelText: 'CANCEL',
						centered: true,
						onOk(){
							self.$api.delete(`/event-groups/:instance/${obj.id}`).then( ({data}) => {
								self.$store.commit('DELETE_EVENT',data)
							})
						},
						onCancel(){
							console.log('CANCELED')
						}
					})
                } else if(item.event.hasOwnProperty('class') && item.event.class == 'opportunity') {
                    let self = this
                    this.$confirm({
                        title: "Delete Opportunity",
                        content: h => <div>Do you want to delete this Opportunity?</div>,
                        okText: 'Delete',
                        okType: 'danger',
                        cancelText: 'Cancel',
                        centered: true,
                        onOk() {
                            self.$api.delete(`/opportunities/:instance/${item.event.id}`).then( ({data}) => {
                                console.log('just delete', data)
                                self.$store.commit('UPDATE_OPPORTUNITIES', data)
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									self.$message.error(self.$err(err))
								}
							})
                        },
                        onCancel() {
                            console.log('Cancel')
                        }
                    })
                }
            },
            cancelEdit(){
                console.log('CANCEL EDIT RAN')
                this.$store.commit('CLOSE_EDIT_TASK')
            },
            cancelEditAppointment(){
                this.$store.commit('CLOSE_APPOINTMENT')
            }

        },
        mounted(){

        },
        created() {
            this.$store.commit('SIDEBAR_SELECTION',{value:'Calendar'})

			if (this.$route.query && this.$route.query.id) {
				const event = this.events.find(e => e.id === this.$route.query.id)
				if (event) {
					setTimeout(() => {
						this.editEvent({event})
					}, 0)
				}
			}

            // $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);

        },
    }

    // function test(){
    //     console.log('AFASFASFA')
    // }
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
    .edit-button:hover{
        color:var(--orange);
        cursor:pointer;
    }
    .delete-button:hover{
        color:var(--danger);
        cursor:pointer;
    }
    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:5%;
        }
    }
    .aB{
        align-items: baseline;
    }
    .calendar-view{
        @media screen and(min-width:1030px){
            display:flex
        }
    }
    .small--calendar{
        @media screen and(min-width:1030px){
            width:350px;
        }
        @media screen and(max-width:1030px){
            width:100%;
        }
    }
    .display-height{
        @media screen and(min-width:1030px){
            height:300px;
        }
    }
    .main--calendar{
        @media screen and(max-width:1030px){
            margin-top:20px;
        }
    }
    .main-height{
        @media screen and(min-width:1030px){
            height:calc(100vh - 199px - 6rem);
        }
    }
</style>

<style lang="scss">
    .smallCalendaEdit{
        border-color:#FFF !important;
    }
    .smallCalendaEdit .vuecal__cell.vuecal__cell--day6 {
        color:var(--orange);
    }
    .smallCalendaEdit .vuecal__cell.vuecal__cell--day6.vuecal__cell--out-of-scope{
        color:#FCDEBD;
    }
    .smallCalendaEdit .vuecal__cell.vuecal__cell--day7 {
        color:var(--orange);
    }
    .smallCalendaEdit .vuecal__cell.vuecal__cell--day7.vuecal__cell--out-of-scope{
        color:#FCDEBD;
    }
    .smallCalendaEdit .vuecal__cell-events-count {
        width: 7px;
        min-width: 0;
        height: 7px;
        padding: 0;
        margin-top:-17px;
        margin-left:8px;
        color: transparent;
        background-color:#FD647C;
    }

    .smallCalendaEdit .vuecal__cell--today .vuecal__cell-content {
        border-color:#9693E7 !important;
    }
    .smallCalendaEdit .vuecal__cell--selected .vuecal__cell-content {
        background-color:#9693E7 !important;
    }
    .smallCalendaEdit .vuecal__weekdays-headings{
        border-bottom:2px solid #D5E1EB;
    }
    // .calendarEdit {
    //     height: auto;
    // }
    .calendarEdit .vuecal__menu {
        display:inline-flex;
        float:right;
        width: calc(100% - 300px);
        padding: 15px 0;
        height: 65px;
        background-color: white;
        font-size: 12px;
        justify-content: flex-end;
        // display:none;
    }
    .smallCalendaEdit .vuecal__title-bar{
        background-color:#FFF;
    }
    .calendarEdit .vuecal__title-bar {
        display:inline-flex;
        float:left;
        width:300px;
        height: 65px;
        background-color: white;
        font-size: 16px;
        // display:none;
    }
    .calendarEdit .vuecal__weekdays-headings {
        width:100%;
        background-color: white;
        border-top: 1px solid #E4E7EB;
        border-right: 1px solid #E4E7EB;
        padding-right: 0;
    }
	.calendarEdit.vuecal--week-view .vuecal__weekdays-headings {
		padding-left: 60px;
	}
    .calendarEdit .vuecal__view-btn{
        border-radius:4px;
        margin-left:5px;
        margin-right:5px
    }
    // .calendarEdit .vuecal__time-column{
    //     display:flex;
    //     flex-direction: column;
    //     justify-content: space-between;
    // }
    .calendarEdit .vuecal__view-btn--active{
        border-bottom-width:0 !important;
        background-color:var(--orange);
        color: white;
    }
    .calendarEdit .vuecal__bg {
        height:100%;
        background-color: white;
        border-left: 1px solid #E4E7EB;
        border-right: 1px solid #E4E7EB;
    }
	.calendarEdit .vuecal__bg::-webkit-scrollbar {
		display: none;
	}
	.calendarEdit .vuecal__bg {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
    // .calendarEdit .vuecal__flex .vuecal__cell-content {
    //     height:100%;
    // }
    .calendarEdit .weekday-label {
        border-left: 1px solid;
        border-color:#E4E7EB;
    }
    .calendarEdit .vuecal__cell-date {
        height:100%;
        text-align: right;
        padding-right: 10px;
        padding-top: 10px;
    }
	.calendarEdit .vuecal__all-day-text {
		width: 60px !important;
	}
	.calendarEdit .vuecal__time-column {
		width: 60px !important;
	}
    .calendarEdit .vuecal__cell-content {
        min-height: 55px;
    }
    .calendarEdit .vuecal__header{
        border-right:1px solid #E4E7EB;
        border-left:1px solid #E4E7EB;
    }
    .calendarEdit .vuecal__event {
        width: 90% ;
    }
    .calendarEdit .vuecal__cell--selected {
        background-color: #F6F9FD;
    }
    .calendarEdit .vuecal__all-day{
        padding-right: 1px;
        padding-left: 1px;
        width: 100%;
    }
    .calendarEdit .vuecal__all-day .vuecal__cell-events .vuecal__event--all-day {
        width: 100%;
    }

    // .vuecal__event.high {border-top: 3px solid var(--danger);}
    // .vuecal__event.medium {border: 1px solid var(--orange);}
    // .vuecal__event.low {border: 1px solid var(--teal);}
    // .vuecal__event.appointment {}
    .vuecal__event{
        margin:0 10px;
        background-color:#FAFAFA;
        width:calc(100% - 20px) !important;
    }
</style>
<style scoped>
.task-container{
    height:0;
    transition:height .3s ease-in-out;
    overflow: hidden;
}
.show-task{
    height:200px;
}
.appointment-container{
    height:0;
    transition:height .3s ease-in-out;
    overflow: hidden;
}
.show-appointment{
    height:200px;
}
a {
	color: var(--primary);
	text-decoration: none;
}
</style>
