import { render, staticRenderFns } from "./TaskandAppointment.vue?vue&type=template&id=c46f3278&scoped=true"
import script from "./TaskandAppointment.vue?vue&type=script&lang=js"
export * from "./TaskandAppointment.vue?vue&type=script&lang=js"
import style0 from "./TaskandAppointment.vue?vue&type=style&index=0&id=c46f3278&prod&lang=scss"
import style1 from "./TaskandAppointment.vue?vue&type=style&index=1&id=c46f3278&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c46f3278",
  null
  
)

export default component.exports